var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.$t("Choose a code type to compose"),
        visible: _vm.visible,
        footer: null
      },
      on: { cancel: _vm.hiddenModal }
    },
    [
      _c(
        "a-radio-group",
        { attrs: { value: _vm.codeType }, on: { change: _vm.changeCodeType } },
        [
          _c(
            "a-radio",
            {
              staticClass: "mb-sm",
              staticStyle: { display: "block" },
              attrs: { value: "0" }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("Voice code with plain content(single/multiples)")
                  ) +
                  " "
              )
            ]
          ),
          _vm.isSupportedRagt
            ? _c(
                "a-radio",
                {
                  staticClass: "mb-sm",
                  staticStyle: { display: "block" },
                  attrs: { value: "1" }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("Voice code with rich content(single/multiples)")
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          _c(
            "a-radio",
            {
              staticClass: "mb-sm",
              staticStyle: { display: "block" },
              attrs: { value: "2" }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("Navi code / Spot code / Mix code")) + " "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content-end" },
        [
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.chooseCodeType } },
            [_vm._v(" " + _vm._s(_vm.$t("Ok")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }