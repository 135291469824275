var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "page-header",
        {
          staticClass: "mb-xs",
          attrs: {
            "show-back-icon": "",
            title: _vm.currentProject.name + " / " + _vm.currentPage.name,
            "show-status": "",
            status: _vm.currentPage.ispublic
          },
          on: { back: _vm.handleBackToPageList }
        },
        [
          _c(
            "template",
            { slot: "extra" },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary", icon: "plus" },
                  on: { click: _vm.goToCodeCompose }
                },
                [_vm._v(_vm._s(_vm.$t("Code compose")))]
              ),
              _c(
                "a-button",
                {
                  staticClass: "ml-xs",
                  attrs: { icon: "code" },
                  on: { click: _vm.openPageEditor }
                },
                [_vm._v(_vm._s(_vm.$t("project.grid.EditWebsite")))]
              ),
              _c(
                "a-button",
                {
                  staticClass: "ml-xs",
                  attrs: { icon: "gold" },
                  on: { click: _vm.goToCategoryDetail }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Category management")) + " ")]
              ),
              _c(
                "a-dropdown",
                { staticClass: "ml-xs", attrs: { trigger: ["click"] } },
                [
                  _c(
                    "a-menu",
                    {
                      attrs: { slot: "overlay" },
                      on: { click: _vm.handleClickMenu },
                      slot: "overlay"
                    },
                    [
                      _c(
                        "a-menu-item",
                        { key: "openSwitchPage" },
                        [
                          _c("a-icon", { attrs: { type: "interaction" } }),
                          _c("span", [_vm._v(_vm._s(_vm.$t("Switch page")))])
                        ],
                        1
                      ),
                      _c(
                        "a-menu-item",
                        { key: "handlePageExport" },
                        [
                          _c("a-icon", { attrs: { type: "export" } }),
                          _c("span", [_vm._v(_vm._s(_vm.$t("Export page")))])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-button", { attrs: { icon: "ellipsis" } })
                ],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      _c("search-box", {
        attrs: {
          display: _vm.queryParam.mode,
          queryParam: _vm.queryParam,
          category: _vm.category
        },
        on: {
          toggleView: _vm.onChangeView,
          handleSearch: _vm.handleSearch,
          changeTitle: _vm.changeTitle,
          changeStatus: _vm.changeStatus,
          handleReset: _vm.handleReset,
          changeCategory: _vm.changeCategory
        }
      }),
      _c(
        "a-spin",
        { attrs: { spinning: _vm.loading } },
        [
          _vm.queryParam.mode === "list"
            ? _c("list-view", {
                attrs: { "row-data": _vm.codes, pagination: _vm.pagination },
                on: {
                  changePaging: _vm.handlePageChanging,
                  updateCodes: _vm.updateCodes,
                  deleteCodes: _vm.deleteCodes,
                  deleteAllCodes: _vm.deleteCodes,
                  copyCodes: _vm.handleCopyCodes,
                  moveCodes: _vm.handleMoveCodes,
                  duplicateCode: _vm.duplicateCode
                }
              })
            : _vm.queryParam.mode === "grid"
            ? _c("grid-view", {
                attrs: { "row-data": _vm.codes, pagination: _vm.pagination },
                on: {
                  changePaging: _vm.handlePageChanging,
                  updateCodes: _vm.updateCodes,
                  deleteCodes: _vm.deleteCodes,
                  copyCodes: _vm.handleCopyCodes,
                  moveCodes: _vm.handleMoveCodes,
                  duplicateCode: _vm.duplicateCode
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("compose-code-type", {
        ref: "composeCodeTypeRef",
        attrs: { pageId: _vm.pageId }
      }),
      _vm.switchPageModalVisible
        ? _c("switch-page-modal", {
            attrs: { visible: _vm.switchPageModalVisible },
            on: {
              selected: _vm.handleSwitchPage,
              close: function() {
                _vm.switchPageModalVisible = false
              }
            }
          })
        : _vm._e(),
      _vm.iframeVisible
        ? _c("iframe-review", {
            attrs: { visible: _vm.iframeVisible, page: _vm.currentPage },
            on: {
              cancel: function($event) {
                _vm.iframeVisible = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }