//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import PageHeader from '@/components/tools/PageHeaderCustom.vue';
import SearchBox from './search-box/SearchBox.vue';
import ComposeCodeType from './modal/ComposeCodeType.vue';
import * as pubsub from '@/pubsub';
import { urlByAnyData } from '@/utils/util';

const PAGE_SIZE = 24;

export default {
  components: {
    PageHeader,
    SearchBox,
    ComposeCodeType,
    ListView: () => import('./list/Index.vue'),
    GridView: () => import('./grid/Index.vue'),
    SwitchPageModal: () => import('./modal/SwitchPageModal.vue'),
    IframeReview: () => import('../iframe-code-list/Index.vue')
  },

  data() {
    return {
      switchPageModalVisible: false,
      iframeVisible: false,

      loading: false,
      queryParam: {
        status: 'all',
        title: '',
        mode: this.$ls.get('mode', 'grid'),
        category: '',
        page: 1,
        size: PAGE_SIZE
      },
      pagination: { current: 1, pageSize: PAGE_SIZE, total: 0 },
      category: {
        options: [],
        normalizer(node) {
          return {
            label: node.name
          };
        }
      }
    };
  },

  created() {
    const { title, status, mode, category, page, size, order } = this.$route.query;
    this.queryParam = {
      title: title || '',
      status: status || 'all',
      mode: mode || this.$ls.get('mode', 'grid'),
      category: category || '',
      page: page || 1,
      size: size || PAGE_SIZE,
      ...(order && { order })
    };
    this.pagination.pageSize = +size || PAGE_SIZE;
    this.pagination.current = +page || 1;
    this.getData();
  },

  computed: {
    ...mapGetters('user', ['userInfo']),
    ...mapGetters('page', ['pageById']),
    ...mapGetters('project', ['projectById']),
    ...mapGetters('code', ['codes', 'codeById']),

    companyId() {
      return this.userInfo.company_id;
    },
    pageId() {
      return this.$route.params.pageId;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    currentPage() {
      return this.pageById(this.pageId) || {};
    },
    currentProject() {
      return this.projectById(this.projectId) || {};
    },

    filter() {
      const { title, status, category, page, size, order } = this.queryParam;
      const where = {
        ...(title && { title: { ilike: `%${title}%` } }),
        ...(status && status !== 'all' && { status: { eq: status } }),
        ...(category && { category_id: { inq: JSON.parse(category) } })
      };
      const skip = ((page || 1) - 1) * (size || PAGE_SIZE);
      const filter = {
        limit: size || PAGE_SIZE,
        skip,
        where,
        order: order || 'order_by desc'
      };
      return filter;
    }
  },

  methods: {
    ...mapActions('code', ['loadCodes', 'reset', 'addCode', 'removeCode', 'updateCode']),
    ...mapActions('category', ['fetchCategories']),
    ...mapActions('page', ['getPageById']),

    async getData() {
      try {
        this.loading = true;
        this.reset();
        this.fetchCategories(this.pageId);
        this.getPageById({ projectId: this.projectId, pageId: this.pageId });
        const codes = await this.$s.api.page.getCodes(this.pageId, { filter: this.filter });
        const count = await this.$s.api.page.countCodes(this.pageId, this.filter.where);
        const result = await this.$s.api.page.getCategories(this.pageId);
        this.category.options = result;
        this.pagination = { ...this.pagination, total: count?.count };
        this.loadCodes({ payload: codes });
        codes.forEach(code => {
          if (
            code.status === 'PENDING' ||
            (code.status === 'PUBLISHED' && code.action === 'UPDATING')
          ) {
            const subscribePayload = {
              collectionName: 'CODE',
              method: 'GET',
              modelId: code.id
            };
            pubsub.subscribe(this.sockets, subscribePayload, newCode => {
              this.updateCode(newCode);
              pubsub.unSubscribe(this.sockets, subscribePayload);
            });
          }
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    handleClickMenu({ key }) {
      this[key]();
    },
    goToCodeCompose() {
      this.$refs.composeCodeTypeRef.showModal();
    },
    openSwitchPage() {
      this.switchPageModalVisible = true;
    },
    handleSwitchPage(page) {
      this.$router.push(`/projects/${page.campaign_id}/pages/${page.id}/codes`);
    },
    openPageEditor() {
      this.iframeVisible = true;
    },
    goToCategoryDetail() {
      this.$router.push(`/projects/${this.projectId}/pages/${this.pageId}/category`);
    },
    async handlePageExport() {
      try {
        await this.$s.api.page.exportPageById(this.pageId, { includes: ['codes'] });
        const subscribePayload = {
          collectionName: 'EXPORT_PAGE',
          method: 'GET',
          modelId: this.pageId
        };
        this.$notification.open({
          message: this.$t('Exporting... Please wait for a little bit!'),
          duration: 0,
          icon: <a-icon type="smile" style="color: #108ee9" />
        });
        pubsub.subscribe(this.sockets, subscribePayload, response => {
          this.$notification.destroy();
          if (response.status === 'FAILED') {
            this.$notification.error({
              message: this.$t('Exporting the page failed.'),
              description: response?.message || ''
            });
          } else {
            const url = urlByAnyData({ data: response?.data, contentType: 'application/json' });
            const a = document.createElement('a');
            a.href = url;
            a.download = this.currentPage.name || 'file-name';
            a.click();
            a.remove();
            this.$notification.success({
              message: this.$t('Exporting the page succeed.')
            });
          }
          pubsub.unSubscribeAll(this.sockets);
        });
      } catch (error) {
        console.log(error);
        this.$notification.error({
          message: this.$t(error?.message || 'error')
        });
      }
    },
    async handleCopyCodes({ selectedPageId, codeIds, resolve, reject }) {
      try {
        await this.$s.api.page.copyCodeToAnotherPage(selectedPageId, codeIds);
        resolve(1);
        this.$notification.success({
          message: this.$t('Copy code to page successfully', {
            name: this.pageById(selectedPageId)?.name || ''
          })
        });
      } catch (error) {
        reject(error);
        this.$notification.error({
          message: this.$t('Error'),
          description: error.message || 'Has an error!',
          duration: 3
        });
      }
    },
    async handleMoveCodes({ selectedPageId, codeIds, resolve, reject }) {
      try {
        await this.$s.api.page.moveCodeToAnotherPage(selectedPageId, codeIds);
        for (let i = 0; i < codeIds.length; i++) {
          this.removeCode({ id: codeIds[i] });
        }
        resolve(1);
        this.$notification.success({
          message: this.$t('Move code to page successfully', {
            name: this.pageById(selectedPageId)?.name || ''
          })
        });
      } catch (error) {
        reject(error);
        this.$notification.error({
          message: this.$t('Error'),
          description: error.message || 'Has an error!',
          duration: 3
        });
      }
    },
    onChangeView(mode) {
      const { query, path } = this.$route;
      this.$ls.set('mode', mode);
      this.$router.replace({ path, query: { ...query, mode: mode } });
    },

    changeTitle(value) {
      this.queryParam.title = value;
    },
    changeStatus(value) {
      this.queryParam.status = value;
      this.$router.replace({ query: this.queryParam });
    },
    createNewCode() {
      this.$refs.composeCodeTypeRef.showModal();
    },
    handleSearch() {
      this.$router.replace({ query: this.queryParam });
    },
    handleReset() {
      Object.assign(this.queryParam, {
        title: '',
        status: 'all',
        mode: this.$route.query.mode || '',
        category: '',
        page: 1,
        size: PAGE_SIZE
      });
      this.handleSearch();
    },
    async duplicateCode({ codeId, resolve, reject }) {
      const code = this.codeById(codeId);
      try {
        const result = await this.$s.api.page.createCodes(this.pageId, {
          ...code,
          title: `${code.title}-Copy`,
          status: 'DRAFT',
          jan_codes: null,
          order_by: null
        });
        this.$notification.success({
          message: this.$t('DuplicateSuccess')
        });
        this.addCode({ payload: { ...result, category: code.category } });
        resolve(1);
      } catch (error) {
        this.$notification.error({
          message: this.$t('Error')
        });
        reject(error);
      }
    },
    handlePageChanging({ pageCurrent, pageSize }) {
      this.$router.replace({ query: { ...this.queryParam, page: pageCurrent, size: pageSize } });
    },
    changeCategory(ids) {
      this.queryParam.category = ids.length ? JSON.stringify(ids) : '';
      this.$router.replace({ query: this.queryParam });
    },

    async updateCodes({ selectedCodes = [], payload = {}, resolve, reject }) {
      try {
        const data = await Promise.all(
          selectedCodes.map(item => this.$s.api.page.editCodeById(this.pageId, item.id, payload))
        );
        data.forEach(item => {
          this.updateCode(item);
          if (
            item.status === 'PENDING' ||
            (item.status === 'PUBLISHED' && item.action === 'UPDATING')
          ) {
            const subscribePayload = { collectionName: 'CODE', method: 'GET', modelId: item.id };
            pubsub.subscribe(this.sockets, subscribePayload, code => {
              this.updateCode(code);
              pubsub.unSubscribe(this.sockets, subscribePayload);
            });
          }
        });
        resolve(1);
      } catch (error) {
        reject(error);
      }
    },
    async deleteCodes({ codeIds, resolve, reject }) {
      try {
        await this.$s.api.page.deleteCodes({
          pageId: this.pageId,
          ids: codeIds
        });
        if (codeIds === 'all') {
          this.reset();
        } else
          for (let i = 0; i < codeIds.length; i++) {
            this.removeCode({ id: codeIds[i] });
          }
        this.pagination = { ...this.pagination, total: 0 };
        this.$notification.success({ message: this.$t('Success') });
        resolve(1);
      } catch (error) {
        reject(error);
      }
    },
    handleBackToPageList() {
      this.$router.go(-1);
    }
  }
};
