//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  props: {
    pageId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      visible: false,
      codeType: '0'
    };
  },
  computed: {
    ...mapGetters('user', ['isSupportedRagt']),
    projectId() {
      return this.$route.params.projectId;
    }
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    hiddenModal() {
      this.visible = false;
    },
    changeCodeType({ target }) {
      this.codeType = target.value;
    },
    chooseCodeType() {
      if (this.codeType === '0') {
        this.$router.push(
          `/projects/${this.projectId}/pages/${this.pageId}/codes/plain-voice-code`
        );
      } else if (this.codeType === '1') {
        this.$router.push(`/projects/${this.projectId}/pages/${this.pageId}/codes/rich-voice-code`);
      } else {
        this.$router.push(`/projects/${this.projectId}/pages/${this.pageId}/codes/navi-code`);
      }
    }
  }
};
